import PropTypes from 'prop-types';
import { twJoin, twMerge } from 'tailwind-merge';

import Heading from 'shopper/components/Heading';
import Text from 'shopper/components/Text';

import { formatAsCurrency, isNumber } from 'lib/number';

const primaryInfoSizeStylesMap = {
  size1: 'size1',
  size2: 'size4',
};

const primaryInfoSignalSizeStylesMap = {
  size1: 'size1',
  size2: 'size4',
};

const secondaryInfoStylesMap = {
  size1: 'size1',
  size2: 'size2',
};

const getCurrencySignal = (currency) =>
  currency.substring(0, currency.search(/\s+/));

const getCurrencyValue = (currency) =>
  currency.substring(currency.search(/\s+/) + 1, currency.length);

const Price = ({
  size = 'size2',
  secondaryInfo,
  primaryInfo,
  mobileOrientation = 'column',
  locale = 'pt-BR',
  currency = 'BRL',
  className,
  ...rest
}) => {
  const primaryInfoSize = primaryInfoSizeStylesMap[size];
  const primaryInfoSignalSize = primaryInfoSignalSizeStylesMap[size];
  const secondaryInfoSize = secondaryInfoStylesMap[size];
  const [currPrimaryInfo, currSecondaryInfo] = [primaryInfo, secondaryInfo].map(
    (info) =>
      isNumber(info)
        ? formatAsCurrency({ number: info, currency, locale })
        : info
  );

  const isSecondaryInfoString =
    typeof secondaryInfo === 'string' || secondaryInfo instanceof String;

  return (
    <div
      className={twMerge(
        'flex w-min flex-col items-start xl:flex-row',
        isSecondaryInfoString && 'xl:flex-row-reverse',
        mobileOrientation === 'row' && 'flex-row-reverse items-center gap-x-1',
        className
      )}
      {...rest}
    >
      {currSecondaryInfo && (
        <Text
          as="span"
          className={twJoin(
            'whitespace-nowrap font-regular xl:order-1 xl:ml-2 xl:mt-1',
            secondaryInfo && 'text-neutral-high-500 dark:text-neutral-high-400',
            secondaryInfo && isNumber(secondaryInfo) && 'line-through',
            isSecondaryInfoString && 'xl:ml-0 xl:mr-1 xl:mt-2'
          )}
          size={secondaryInfoSize}
        >
          {currSecondaryInfo}
        </Text>
      )}
      {isNumber(primaryInfo) ? (
        <div className="lg:order-0 items-center">
          <Heading
            as="span"
            className="pr-1 text-neutral-low-500 dark:text-neutral-high-200"
            size={primaryInfoSignalSize}
          >
            {getCurrencySignal(currPrimaryInfo)}
          </Heading>
          <Heading
            as="span"
            className="whitespace-nowrap text-neutral-low-500 dark:text-neutral-high-200"
            size={primaryInfoSize}
          >
            {getCurrencyValue(currPrimaryInfo)}
          </Heading>
        </div>
      ) : (
        <Heading
          as="span"
          className="whitespace-nowrap text-neutral-low-500 dark:text-neutral-high-200"
          size={primaryInfoSize}
        >
          {currPrimaryInfo}
        </Heading>
      )}
    </div>
  );
};

Price.propTypes = {
  /**
   * Sets primary info. If a number is passed it will be formatted
   * using `currency` and `locale` props, otherwise it is rendered
   * normally as a string
   */
  primaryInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /**
   * Sets secondary info. If a number is passed it will be formatted
   * using `currency` and `locale` props, otherwise it is rendered
   * normally as a string
   */
  secondaryInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Renders a text with a determined size
   */
  size: PropTypes.oneOf(['size1', 'size2']),
  /**
   * Currency used to format `primaryInfo` and `secondaryInfo` props.
   * A Intl.NumberFormat constructor option `currency`
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
   */
  currency: PropTypes.string,
  mobileOrientation: PropTypes.oneOf(['row', 'column']),
  /**
   * Locale used to format `primaryInfo` and `secondaryInfo` props.
   * A string with a BCP 47 language tag
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation
   */
  locale: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback function on click
   */
  onClick: PropTypes.func,
};
export default Price;
