import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { twJoin } from 'tailwind-merge';

import Price from 'shopper/components/Price/V2';
import Tag from 'shopper/components/Tag/V2';
import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import useExclusiveOffer from 'hooks/useExclusiveOffer';

import { sendEvent } from 'lib/gtag';
import { getOfferImageLink, toOfferImageAltText } from 'lib/image';
import { toOfferLink } from 'lib/links';
import { toFormattedOfferOldPrice, toFormattedOfferPrice } from 'lib/offer';
import { toFormattedGaEventActionPrefix } from 'lib/string';
import { noop } from 'lib/utils';

import { useUser } from 'providers/UserProvider';

import Footer from './Footer';
import Store from './Store';

import OFFER from 'constants/offer';

const OfferGridCard = ({
  authors = [],
  cardTitleAs = 'span',
  gaEventCategory,
  gaEventActionPrefix = null,
  offerComments,
  offerId,
  offerImagePriority = false,
  offerLikes,
  offerOldPrice = null,
  offerPhoto,
  offerPrice,
  offerPriceType,
  offerPublished,
  offerSlug,
  offerStatusName,
  offerTags = [],
  offerTitle,
  offerUserVisibility,
  storeDomain,
  userId,
  userName,
  userPhoto,
  onImageClick = noop,
  onTitleClick = noop,
}) => {
  const { isOfferBlocked, onUnloggedUserTriesOpenExclusiveOffer } =
    useExclusiveOffer();
  const { isLoggedIn } = useUser();

  const onClick = (event) => {
    if (!isOfferBlocked(offerUserVisibility)) {
      return;
    }

    sendEvent({
      action: 'click__offer__exclusive',
      category: gaEventCategory,
      logged: isLoggedIn,
    });

    event.preventDefault();
    onUnloggedUserTriesOpenExclusiveOffer(offerSlug);
  };

  const onImageClickHandler = useCallback(
    (e) => {
      if (gaEventCategory) {
        sendEvent({
          category: gaEventCategory,
          action: `${toFormattedGaEventActionPrefix(
            gaEventActionPrefix
          )}product_image`,
        });
      }

      onImageClick(e);
    },
    [gaEventCategory, gaEventActionPrefix]
  );

  const onTitleClickHandler = useCallback(
    (e) => {
      if (gaEventCategory) {
        sendEvent({
          category: gaEventCategory,
          action: `${toFormattedGaEventActionPrefix(
            gaEventActionPrefix
          )}product_title`,
        });
      }

      onTitleClick(e);
    },
    [gaEventCategory, gaEventActionPrefix]
  );

  return (
    <NextLink href={toOfferLink(offerSlug)} prefetch={false} passHref>
      <Anchor
        className="relative flex h-full select-none flex-col justify-between rounded-4 border border-neutral-high-200 p-6 shadow-sm transition-shadow hover:shadow-md dark:border-neutral-low-200 lg:hover:ease-in"
        onClick={onClick}
      >
        <div className="relative flex size-full flex-col">
          <Store className="flex pb-2" storeDomain={storeDomain} />
          <div className="relative flex justify-center">
            <Image
              alt={toOfferImageAltText(offerTitle)}
              className={twJoin(
                'flex self-center rounded-4',
                offerStatusName === OFFER.STATUSES.FINISHED && 'opacity-30'
              )}
              grayscale={offerStatusName === OFFER.STATUSES.FINISHED}
              height={180}
              loading={offerImagePriority ? null : 'lazy'}
              priority={offerImagePriority}
              sizes="(max-width: 768px) 120px, 200px"
              src={getOfferImageLink(offerPhoto, 120)}
              srcSet={`
                  ${getOfferImageLink(offerPhoto, 180)} 180w,
                  ${getOfferImageLink(offerPhoto, 300)} 300w,
                `}
              width={180}
              onClick={onImageClickHandler}
            />
            {offerTags.length > 0 && (
              <div className="absolute -bottom-1 left-0 flex">
                <Tag className="whitespace-pre px-2 py-1" size="size3" filled>
                  {offerTags[0]?.name}
                </Tag>
              </div>
            )}
          </div>
          <div className="flex size-full flex-col justify-between border-b border-neutral-high-300 pb-4 dark:border-neutral-low-300">
            <Text
              as={cardTitleAs}
              className="mt-3 line-clamp-2 min-h-12 w-full break-words"
              onClick={onTitleClickHandler}
            >
              {offerTitle}
            </Text>
            <Price
              className="mt-2"
              mobileOrientation="row"
              primaryInfo={toFormattedOfferPrice(offerPrice, offerPriceType)}
              secondaryInfo={toFormattedOfferOldPrice(
                offerOldPrice,
                offerPriceType
              )}
            />
          </div>
        </div>
        <Footer
          authors={authors}
          gaEventCategory={gaEventCategory}
          likesCount={offerLikes}
          offerComments={offerComments}
          offerId={offerId}
          offerPublished={offerPublished}
          userId={userId}
          userName={userName}
          userPhoto={userPhoto}
        />
      </Anchor>
    </NextLink>
  );
};

OfferGridCard.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      typeName: PropTypes.string.isRequired,
      specialityName: PropTypes.string,
      level: PropTypes.number.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  cardTitleAs: PropTypes.string,
  gaEventCategory: PropTypes.string.isRequired,
  gaEventActionPrefix: PropTypes.string,
  offerComments: PropTypes.number.isRequired,
  offerIsHighlight: PropTypes.bool.isRequired,
  offerImagePriority: PropTypes.bool,
  offerOldPrice: PropTypes.number,
  offerPhoto: PropTypes.string.isRequired,
  offerPrice: PropTypes.number.isRequired,
  offerPriceType: PropTypes.string.isRequired,
  offerPublished: PropTypes.string.isRequired,
  offerSlug: PropTypes.string.isRequired,
  offerStatusName: PropTypes.string.isRequired,
  offerUserVisibility: PropTypes.string.isRequired,
  offerTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
  offerTitle: PropTypes.string.isRequired,
  storeDomain: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string.isRequired,
  userUsername: PropTypes.string.isRequired,
  onImageClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};

export default OfferGridCard;
